import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.cjs");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@liquorice/next-apollo/src/ApolloWrapper/ApolloWrapperInternal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/Accordion/AccordionMulti.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/Atoms/Button/ScrollToTopButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchIcon"] */ "/vercel/path0/frontend/src/components/Atoms/Icons/SearchIcon.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Atoms/ImageAsset/ImageAsset.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/Blocks/BlockAnchors.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Blocks/blockComponents/FormBlock/FormBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollingImageBlock"] */ "/vercel/path0/frontend/src/components/Blocks/blockComponents/ImageCarouselBlock/ScrollingImageBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MapClientBlock"] */ "/vercel/path0/frontend/src/components/Blocks/blockComponents/MapBlock/MapClientBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PeopleBlock"] */ "/vercel/path0/frontend/src/components/Blocks/blockComponents/PeopleBlock/PeopleBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/LatestNewsArticles/LatestNewsArticles.tsx");
