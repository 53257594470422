import { gql } from '@/__generated__';
import { Button } from '@/components/Atoms/Button';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { parseLinkField } from '@/lib/parsers/linkField';

const CTA_BLOCK_FRAGMENT = gql(`
  fragment CtaBlock on blocks_blockCta_BlockType {
    id
    heading
    htmlContentSimple
    linkSingle {
      ...LinkField
    }
  }
`);

export interface CtaBlockProps extends BlockMeta {}

const CtaBlock = createBlock(CTA_BLOCK_FRAGMENT, (data, props: CtaBlockProps) => {
  const link = parseLinkField(data.linkSingle);

  return (
    <FluidContainer id={props.anchor}>
      <div className="bg-tertiary flex w-full items-center justify-center rounded-4xl p-16">
        <div className="flex max-w-screen-sm flex-col justify-center gap-6 text-center">
          <h3>{data.heading}</h3>
          <div
            className="text-black"
            dangerouslySetInnerHTML={{ __html: data.htmlContentSimple ?? '' }}
          />
          {link && (
            <div>
              <Button color="midnightBlue" size="medium" arrow href={link.href}>
                {link.children || 'Find out more'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </FluidContainer>
  );
});

export default CtaBlock;
