'use client';

import { withFragmentArray } from '@liquorice/gql-utils';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { FluidContainer } from '../Atoms/Layouts';
import { BLOCKS_FRAGMENT } from './Blocks';
import { getBlockMeta } from './createBlock';

const scrollToSection = (id: string) => {
  const section = document.getElementById(id);
  if (section) {
    const topViewportOffset = 30; // Offset from the top of the viewport in px
    const elementPosition = section.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - topViewportOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, id: string | undefined) => {
  event.preventDefault(); // Prevent default anchor behavior
  id && scrollToSection(id);
};

const BlockAnchors = withFragmentArray(BLOCKS_FRAGMENT, (data) => {
  if (!data) return null;

  const pathname = usePathname(); // Get current path, including hash if present
  const blockAnchors = data?.map((block) => getBlockMeta(block)).filter(Boolean);

  useEffect(() => {
    const hash = window.location.hash; // Get hash from the URL
    if (hash) {
      const id = hash.replace('#', '');
      setTimeout(() => scrollToSection(id), 100); // Delay for DOM readiness
    }
  }, [pathname]);

  if (!blockAnchors || blockAnchors.length === 0) return null;

  return (
    <FluidContainer width="article">
      <div className="text-tBase bg-secondary overflow-hidden rounded-3xl p-8 lg:rounded-[32px]">
        <h5 className="mb-4">{`On this page:`}</h5>
        <ul>
          {blockAnchors.map(
            (blockAnchor, i) =>
              blockAnchor && (
                <li key={`${blockAnchor.anchor}-${i}`} className="mb-2">
                  <a
                    href={`#${blockAnchor.anchor}`}
                    onClick={(e) => handleClick(e, blockAnchor.anchor)}
                    className="underline">
                    <p className="text-m">{blockAnchor.anchorLabel}</p>
                  </a>
                </li>
              ),
          )}
        </ul>
      </div>
    </FluidContainer>
  );
});

export default BlockAnchors;
