import { gql } from '@/__generated__';
import { withFragment } from '@liquorice/gql-utils';
import { CardBase, CardInfo } from '..';

export const PAGE_CARD_FRAGMENT = gql(`
  fragment PageCard on page_Entry {
    __typename
    id
    title
    url
    summary
    featureImage {
      ...ImageAsset
    }
    sectionHandle
  }
`);

const PageCard = withFragment(PAGE_CARD_FRAGMENT, (data) => {
  return (
    <CardBase>
      <CardInfo
        title={data?.title || ''}
        description={data?.summary || ''}
        linkHref={data?.url || '#'}
      />
    </CardBase>
  );
});

export default PageCard;
