import { gql } from '@/__generated__';
import { FluidContainer, Responsive3ColGrid } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import PageCard from '@/components/Cards/PageCard/PageCard';
import { excludeEmptyTypedElements } from '@liquorice/utils';

export const INTERNAL_PAGE_CARDS_BLOCK_FRAGMENT = gql(`
  fragment InternalPageCardsBlock on blocks_blockInternalPageCards_BlockType {
    id
    entryMultiple {
      ...PageCard
    }
  }
`);

export interface InternalPageCardsBlockProps extends BlockMeta {}

const InternalPageCardsBlock = createBlock(
  INTERNAL_PAGE_CARDS_BLOCK_FRAGMENT,
  (data, props: InternalPageCardsBlockProps) => {
    const pages = excludeEmptyTypedElements(data.entryMultiple);

    return (
      <FluidContainer id={props.anchor}>
        <Responsive3ColGrid>
          {pages.map((page, i) => {
            return <PageCard key={i} data={page} />;
          })}
        </Responsive3ColGrid>
      </FluidContainer>
    );
  },
);

export default InternalPageCardsBlock;
