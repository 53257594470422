import { gql } from '@/__generated__';
import { BlocksFragment } from '@/__generated__/graphql';
import { withFragmentArray } from '@liquorice/gql-utils';
import { assertUnreachable } from '@liquorice/utils';
import AccordionBlock from './blockComponents/AccordionBlock';
import BlockquoteBlock from './blockComponents/BlockquoteBlock';
import CtaBlock from './blockComponents/CtaBlock';
import DownloadsBlock from './blockComponents/DownloadsBlock';
import FormBlock from './blockComponents/FormBlock';
import ImageAndTextBlock from './blockComponents/ImageAndTextBlock';
import ImageBlock from './blockComponents/ImageBlock';
import ImageCarouselBlock from './blockComponents/ImageCarouselBlock';
import InternalPageCardsBlock from './blockComponents/InternalPageCardsBlock';
import LatestNewsBlock from './blockComponents/LatestNewsBlock';
import MapBlock from './blockComponents/MapBlock';
import ProfileAccordion from './blockComponents/ProfileAccordion';
import RichTextBlock from './blockComponents/RichTextBlock';
import ServicesBlock from './blockComponents/ServicesBlock';
import VideoBlock from './blockComponents/VideoBlock';

export const BLOCKS_FRAGMENT = gql(`
  fragment Blocks on NeoBlockInterface {
    __typename
    id
    ...AccordionBlock
    ...BlockquoteBlock
    ...CtaBlock
    ...DownloadsBlock
    ...FormBlock
    ...ImageBlock
    ...ImageCarouselBlock
    ...LatestNewsBlock
    ...MapBlock
    ...RichTextBlock
    ...ServicesBlock
    ...VideoBlock
    ...ImageAndTextBlock
    ...ProfileAccordion
    ...InternalPageCardsBlock
  }
`);

interface BlockViewProps {
  data?: BlocksFragment;
  index: number;
}

const BlockView = ({ data, index }: BlockViewProps) => {
  if (!data) return null;

  switch (data.__typename) {
    case 'blocks_blockAccordion_BlockType':
      return <AccordionBlock data={data} />;
    case 'blocks_blockBlockquote_BlockType':
      return <BlockquoteBlock data={data} />;
    case 'blocks_blockCta_BlockType':
      return <CtaBlock data={data} />;
    case 'blocks_blockDownloads_BlockType':
      return <DownloadsBlock data={data} />;
    case 'blocks_blockForm_BlockType':
      return <FormBlock data={data} />;
    case 'blocks_blockImage_BlockType':
      return <ImageBlock data={data} />;
    case 'blocks_blockImageCarousel_BlockType':
      return <ImageCarouselBlock data={data} />;
    case 'blocks_blockLatestNews_BlockType':
      return <LatestNewsBlock data={data} />;
    case 'blocks_blockMap_BlockType':
      return <MapBlock data={data} />;
    case 'blocks_blockRichText_BlockType':
      return <RichTextBlock data={data} />;
    case 'blocks_blockServices_BlockType':
      return <ServicesBlock data={data} />;
    case 'blocks_blockVideo_BlockType':
      return <VideoBlock data={data} />;
    case 'blocks_blockImageAndText_BlockType':
      return <ImageAndTextBlock data={data} index={index} />;
    case 'blocks_profileAccordion_BlockType':
      return <ProfileAccordion data={data} />;
    case 'blocks_blockInternalPageCards_BlockType':
      return <InternalPageCardsBlock data={data} />;
    case 'blocks_profileAccordionItem_BlockType':
      return null;
    default:
      return assertUnreachable(data);
  }
};

const Blocks = withFragmentArray(BLOCKS_FRAGMENT, (data) => {
  return data.map((block, index) => <BlockView key={block.id} data={block} index={index} />);
});

export default Blocks;
