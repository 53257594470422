const DownloadIcon = ({ className = 'stroke-green-600' }: { className?: string }) => (
  <svg
    width="33"
    height="33"
    className={`h-[2em] w-[2em] ${className}`}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.4941 20.0332V25.3665C28.4941 26.0738 28.2132 26.7521 27.7131 27.2522C27.213 27.7523 26.5347 28.0332 25.8275 28.0332H7.16081C6.45356 28.0332 5.77529 27.7523 5.27519 27.2522C4.77509 26.7521 4.49414 26.0738 4.49414 25.3665V20.0332"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M9.82812 13.3662L16.4948 20.0329L23.1615 13.3662"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path d="M16.4941 19.0332V4.0332" strokeWidth="2" strokeLinecap="square" />
  </svg>
);

export default DownloadIcon;
