import { gql } from '@/__generated__';
import { FluidContainer, RoundedSection } from '@/components/Atoms/Layouts';
import { LinkArrow } from '@/components/Atoms/LinkArrow/LinkArrow';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { LatestNewsArticles } from '@/components/LatestNewsArticles';
import { parseLinkField } from '@/lib/parsers/linkField';

const LATEST_NEWS_BLOCK_FRAGMENT = gql(`
  fragment LatestNewsBlock on blocks_blockLatestNews_BlockType {
    id
    heading
    entryLinkSingle {
      ...LinkField
    }
  }
`);

export interface LatestNewsBlockProps extends BlockMeta {}

const LatestNewsBlock = createBlock(
  LATEST_NEWS_BLOCK_FRAGMENT,
  (data, props: LatestNewsBlockProps) => {
    const link = parseLinkField(data.entryLinkSingle);

    return (
      <RoundedSection color="turquoise" noOverlap id={props.anchor}>
        <FluidContainer bottomPadding={false}>
          <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between">
            <h1>{data.heading}</h1>
            {link?.href && (
              <LinkArrow color="midnightBlue" linkHref={link.href}>
                {link.children}
              </LinkArrow>
            )}
          </div>
          <LatestNewsArticles />
        </FluidContainer>
      </RoundedSection>
    );
  },
);

export default LatestNewsBlock;
