import { gql } from '@/__generated__';
import ImageAsset from '@/components/Atoms/ImageAsset';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';

const IMAGE_BLOCK_FRAGMENT = gql(`
  fragment ImageBlock on blocks_blockImage_BlockType {
    id
    image {
      ...ImageAsset
    }
    caption
  }
`);

export interface ImageBlockProps extends BlockMeta {}

const ImageBlock = createBlock(IMAGE_BLOCK_FRAGMENT, (data, props: ImageBlockProps) => {
  return (
    <FluidContainer width="article" id={props.anchor}>
      <ImageAsset data={data.image} className="rounded-3xl md:rounded-[32px] lg:rounded-5xl" />
      {data.caption && <p className="mt-5">{data.caption}</p>}
    </FluidContainer>
  );
});

export default ImageBlock;
