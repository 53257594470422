import { gql } from '@/__generated__';
import { Button } from '@/components/Atoms/Button';
import ImageAsset from '@/components/Atoms/ImageAsset';
import { FluidContainer, TwoColumn } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { parseLinkField } from '@/lib/parsers/linkField';

const IMAGE_AND_TEXT_BLOCK_FRAGMENT = gql(`
  fragment ImageAndTextBlock on blocks_blockImageAndText_BlockType {
    id
    heading
    htmlContentSimple
    linkSingle {
      ...LinkField
    }
    image {
      ...ImageAsset
    }
    imageAlignment
  }
`);

export interface ImageAndTextBlockProps extends BlockMeta {
  index: number;
}

const ImageAndTextBlock = createBlock(
  IMAGE_AND_TEXT_BLOCK_FRAGMENT,
  (data, props: ImageAndTextBlockProps) => {
    const autoAlignment = props.index % 2 === 0 ? 'right' : 'left';
    const alignment = data.imageAlignment === 'auto' ? autoAlignment : data.imageAlignment; // 'left' | 'right' | 'auto'
    const link = parseLinkField(data.linkSingle);
    const { heading, htmlContentSimple, image } = data;

    return (
      <FluidContainer id={props.anchor}>
        <TwoColumn
          reverse={alignment === 'right'}
          leftContent={
            <div className="bg-tertiary h-auto w-full overflow-hidden rounded-3xl md:rounded-4xl lg:rounded-5xl">
              {image && <ImageAsset data={image} />}
            </div>
          }
          rightContent={
            <div className="lg:px-14">
              {heading && <h3 className="mb-6">{heading}</h3>}
              {htmlContentSimple && (
                <div
                  className="mb-8 text-black"
                  dangerouslySetInnerHTML={{ __html: htmlContentSimple }}
                />
              )}
              {link?.href && <Button {...link} color="midnightBlue" arrow />}
            </div>
          }
        />
      </FluidContainer>
    );
  },
);

export default ImageAndTextBlock;
