import { gql } from '@/__generated__';
import DownloadIcon from '@/components/Atoms/Icons/DownloadIcon';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { parseLinkFields } from '@/lib/parsers/linkField';

const DOWNLOADS_BLOCK_FRAGMENT = gql(`
  fragment DownloadsBlock on blocks_blockDownloads_BlockType {
    id
    heading
    linkAssetMultiple {
      ...LinkField
    }
  }
`);

const parseMimeType = (mimeType: string) => {
  return mimeType.split('/')[1].toUpperCase();
};

const formatFileSize = (size: number) => {
  return `${(size / 1000000).toFixed(1)}mb`;
};

export interface DownloadsBlockProps extends BlockMeta {}

const DownloadsBlock = createBlock(DOWNLOADS_BLOCK_FRAGMENT, (data, props: DownloadsBlockProps) => {
  const links = parseLinkFields(data.linkAssetMultiple);

  // dummy mime type and file size
  const mimeType = 'application/pdf';
  const fileSize = 1234567;

  return (
    <FluidContainer width="article" id={props.anchor}>
      <h3 className="mb-5">{data.heading}</h3>
      <ul className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:gap-8">
        {links.map((link, index) => (
          <li
            key={index}
            className="bg-tertiary hover:bg-secondary relative flex items-center justify-between rounded-xl p-4">
            <div>
              <a {...link} className="before:absolute before:inset-0" />
              <br />
              <span className="mt-2 inline-block border border-midnight-blue-500 px-2 py-2 text-xs font-medium leading-none">
                {parseMimeType(mimeType)} {formatFileSize(fileSize)}
              </span>
            </div>
            <DownloadIcon className="stroke-bgPrimary shrink-0" />
          </li>
        ))}
      </ul>
    </FluidContainer>
  );
});

export default DownloadsBlock;
