import { gql } from '@/__generated__';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { YouTubeEmbed } from '@next/third-parties/google';

const VIDEO_BLOCK_FRAGMENT = gql(`
  fragment VideoBlock on blocks_blockVideo_BlockType {
    id
    videoUrl
    caption
  }
`);

interface VimeoEmbedProps {
  vimeoId: string;
}

const VimeoEmbed: React.FC<VimeoEmbedProps> = ({ vimeoId }) => {
  const embedUrl = `https://player.vimeo.com/video/${vimeoId}`;

  return (
    <div className="aspect-h-9 aspect-w-16">
      <iframe
        src={embedUrl}
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="Vimeo Video"></iframe>
    </div>
  );
};

const getYoutubeId = (url?: string | null) => {
  if (!url) return null;
  const match = url.match(
    /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|[^#]*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/,
  );
  return match ? match[1] : null;
};

const getVimeoId = (url?: string | null) => {
  if (!url) return null;
  const match = url.match(/(?:vimeo\.com\/|video\/)(\d+)/);
  return match ? match[1] : null;
};

export interface VideoBlockProps extends BlockMeta {}

const VideoBlock = createBlock(VIDEO_BLOCK_FRAGMENT, (data, props: VideoBlockProps) => {
  const youtubeId = getYoutubeId(data.videoUrl);
  const vimeoId = getVimeoId(data.videoUrl);
  if (!youtubeId && !vimeoId) {
    return null;
  }
  return (
    <FluidContainer width="article" id={props.anchor}>
      <div className="overflow-hidden rounded-xl md:rounded-3xl">
        {youtubeId && <YouTubeEmbed videoid={youtubeId} style="max-width:none;" />}
        {vimeoId && <VimeoEmbed vimeoId={vimeoId} />}
      </div>
      {data.caption && <p className="mt-5">{data.caption}</p>}
    </FluidContainer>
  );
});

export default VideoBlock;
