import { gql } from '@/__generated__';
import { CategoryFragment, EntryBaseFragment, ProfileEntryFragment } from '@/__generated__/graphql';
import { MaybeImage } from '@/components/Atoms/ImageAsset';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { PeopleBlock } from '../PeopleBlock';

export const PROFILE_ACCORDION_ITEM_BLOCK_FRAGMENT = gql(`
  fragment ProfileAccordionItem on blocks_profileAccordionItem_BlockType {
    id
    heading
    profileMultiple {
      ...ProfileEntry
    }
  }
`);

export interface ProfileAccordionProps extends BlockMeta {}

export type ProfilePersonEntry = ProfileEntryFragment & EntryBaseFragment;

const ProfileAccordionItem = createBlock(PROFILE_ACCORDION_ITEM_BLOCK_FRAGMENT, (data) => {
  const profiles = excludeEmptyTypedElements(data.profileMultiple);
  const filteredProfiles = profiles.filter(
    (profile) => !!profile && profile.__typename === 'profile_Entry',
  ) as ProfilePersonEntry[];

  const people = filteredProfiles.map((profile) => ({
    id: profile.id || '',
    name: profile.title || '',
    role: profile.profileRoleMultiple as CategoryFragment[],
    bio: profile.htmlContentDefault || '',
    image: profile.featureImage as MaybeImage,
  }));

  return <PeopleBlock title={data.heading || ''} people={people} />;
});

export const PROFILE_ACCORDION_BLOCK_FRAGMENT = gql(`
  fragment ProfileAccordion on blocks_profileAccordion_BlockType {
    id
    children {
      ...ProfileAccordionItem
    }
  }
`);

const ProfileAccordion = createBlock(
  PROFILE_ACCORDION_BLOCK_FRAGMENT,
  (data, props: ProfileAccordionProps) => {
    const items = excludeEmptyTypedElements(data.children);

    return (
      <FluidContainer id={props.anchor}>
        {items.map((item, index) => (
          <ProfileAccordionItem key={index} data={item} />
        ))}
      </FluidContainer>
    );
  },
);

export default ProfileAccordion;
