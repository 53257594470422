import { gql } from '@/__generated__';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { ScrollingImageBlock } from './ScrollingImageBlock';

const IMAGE_CAROUSEL_BLOCK_FRAGMENT = gql(`
  fragment ImageCarouselBlock on blocks_blockImageCarousel_BlockType {
    id
    heading
    images {
      ...ImageAsset
    }
    caption
  }
`);

export interface ImageCarouselBlockProps extends BlockMeta {}

const ImageCarouselBlock = createBlock(
  IMAGE_CAROUSEL_BLOCK_FRAGMENT,
  (data, props: ImageCarouselBlockProps) => {
    return (
      <FluidContainer width="article" id={props.anchor}>
        <h1>{data.heading}</h1>
        <ScrollingImageBlock data={data.images} controls="bottom" caption={data.caption ?? ''} />
      </FluidContainer>
    );
  },
);

export default ImageCarouselBlock;
