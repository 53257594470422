import { gql } from '@/__generated__';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';

const BLOCKQUOTE_BLOCK_FRAGMENT = gql(`
  fragment BlockquoteBlock on blocks_blockBlockquote_BlockType {
    id
    htmlContentSimple
  }
`);

export interface BlockquoteBlockProps extends BlockMeta {}

const BlockquoteBlock = createBlock(
  BLOCKQUOTE_BLOCK_FRAGMENT,
  (data, props: BlockquoteBlockProps) => {
    return (
      <FluidContainer width="article" id={props.anchor}>
        <div className="text-tBase relative mx-auto max-w-screen-lg">
          <div className="absolute left-0 top-0 text-6xl leading-none">&ldquo;</div>
          <blockquote
            className="prose-lg max-w-none pl-12 pr-12 text-lg"
            dangerouslySetInnerHTML={{ __html: data.htmlContentSimple ?? '' }}
          />
          <div className="absolute -bottom-6 right-0 text-6xl leading-none">&rdquo;</div>
        </div>
      </FluidContainer>
    );
  },
);

export default BlockquoteBlock;
