import { gql } from '@/__generated__';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { MapClientBlock } from './MapClientBlock';

const MAP_BLOCK_FRAGMENT = gql(`
  fragment MapBlock on blocks_blockMap_BlockType {
    id
    heading
    showPreciseAddress
    address {
      ...MapAddress
    }
    htmlContentSimple
  }
`);

export interface MapBlockProps extends BlockMeta {}

const MapBlock = createBlock(MAP_BLOCK_FRAGMENT, (data, props: MapBlockProps) => {
  return (
    <FluidContainer width="article" id={props.anchor}>
      <h3>{data.heading}</h3>
      <MapClientBlock address={data.address} precise={!!data.showPreciseAddress} />
      <div className="mt-5" dangerouslySetInnerHTML={{ __html: data.htmlContentSimple || '' }} />
    </FluidContainer>
  );
});

export default MapBlock;
