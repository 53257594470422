import { gql } from '@/__generated__';
import { FluidContainer, Responsive3ColGrid } from '@/components/Atoms/Layouts';
import { LinkArrow } from '@/components/Atoms/LinkArrow/LinkArrow';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { ServicesCard } from '@/components/Cards';
import { parseLinkField } from '@/lib/parsers/linkField';

const SERVICES_BLOCK_FRAGMENT = gql(`
  fragment ServicesBlock on blocks_blockServices_BlockType {
    id
    heading
    entryLinkSingle {
      ...LinkField
    }
    serviceMultiple {
      ...ServiceCard
    }
  }
`);

export interface BlockServicesProps extends BlockMeta {}

const ServicesBlock = createBlock(SERVICES_BLOCK_FRAGMENT, (data) => {
  if (!data) return null;

  const heading = data.heading;

  const link = parseLinkField(data.entryLinkSingle);
  const cards = data?.serviceMultiple;

  return (
    <FluidContainer>
      <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between">
        <h1>{heading}</h1>
        {link?.href && <LinkArrow linkHref={link.href}>{link.children}</LinkArrow>}
      </div>
      <Responsive3ColGrid>
        {cards.map((card, index) => {
          if (card && card.__typename === 'page_Entry') {
            return <ServicesCard key={index} data={card} />;
          }
          return null;
        })}
      </Responsive3ColGrid>
    </FluidContainer>
  );
});

export default ServicesBlock;
