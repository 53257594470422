import { gql } from '@/__generated__';
import { Button } from '@/components/Atoms/Button';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { parseLinkField } from '@/lib/parsers/linkField';

export const RICH_TEXT_BLOCK_FRAGMENT = gql(`
  fragment RichTextBlock on blocks_blockRichText_BlockType {
    id
    heading
    htmlContentDefault
    linkSingle {
      ...LinkField
    }
  }
`);

export interface RichTextBlockProps extends BlockMeta {}

const RichTextBlock = createBlock(RICH_TEXT_BLOCK_FRAGMENT, (data, props: RichTextBlockProps) => {
  const link = parseLinkField(data.linkSingle);

  return (
    <FluidContainer width="article" id={props.anchor}>
      {data.heading && <h1 className="mb-6">{data.heading}</h1>}
      <div
        className="genu-tables prose prose-lg mb-6 max-w-none prose-li:m-0"
        dangerouslySetInnerHTML={{ __html: data.htmlContentDefault || '' }}
      />
      {link?.href && (
        <Button href={link.href} color="midnightBlue" arrow>
          {link.children}
        </Button>
      )}
    </FluidContainer>
  );
});

export default RichTextBlock;
